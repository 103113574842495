@use '../../includes' as *;

.passport_faq_item {
  margin-block-end: clamp($g24, calc(var(--spacing-block) / 2), $g40);
}

.passport_faq_question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  h3 {
    @include h5;
  }

  button {
    all: unset;
    cursor: pointer;
  }

  &:hover,
  &:focus-visible,
  &:active {
    h3 {
      transition: color var(--duration-half) ease-in;
      color: $pebble;
    }

    svg {
      transition: fill var(--duration-half) ease-in;
      fill: $pebble;

      rect {
        fill: $pebble;
      }
    }
  }

  @include breakpoint($below-sm) {
    h3 {
      font-size: $f12;
    }
  }

  @include breakpoint($sm) {
    margin-inline: clamp($g16, var(--spacing-inline), $g32);
  }
}

.passport_faq_toggle_icon {
  width: 16px;
  height: 16px;
  margin-inline-start: $g12;
  fill: white;
}

.passport_faq_answer {
  display: flex;
  flex-direction: column;
  gap: $g16;
  margin-block-start: $g16;
  padding: clamp($g16, var(--spacing-inline), $g32);
  border: 1px solid white;
  border-radius: $radius-large;
  cursor: pointer;

  @include body-copy-3;

  @include breakpoint($sm) {
    @include body-copy-2;
  }

  .passport_faq_app_logos {
    display: flex;
    container-type: inline-size;
    grid-area: logos;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 2%;
    row-gap: 4%;

    img {
      width: unset;
      max-height: 14px;
      margin-block-end: $g12;

      @include breakpoint(718px) {
        margin-block-end: 0;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    color: $yellow;

    /* stylelint-disable-next-line no-descending-specificity */
    &:hover,
    &:focus-visible,
    &:active {
      transition: opacity var(--duration-half) ease-in;
      opacity: 0.7;
    }

    &:focus-visible {
      outline: $focusstatelight;
      opacity: 0.7;
    }
  }
}
