@use '../../includes' as *;

.passport_feature_table__wrapper {
  margin-block: var(--spacing-block);
  padding-inline: var(--spacing-inline);
}

.passport_feature_table__title {
  @include h2;

  text-align: center;

  @include breakpoint($sm) {
    text-align: start;
  }
}

.passport_feature_table_full {
  display: none;

  @include breakpoint($sm) {
    display: table;
    margin-block-start: $g32;
    border-collapse: collapse;
    // This uses just CSS to hide the outside edge borders on the table
    // Previously we were using a .last_row class to figure that out
    // https://css-tricks.com/table-with-borders-only-on-the-inside/#aa-possibility-3-using-border-style-hidden
    border-style: hidden;

    tr {
      border-block-end: 1px solid $slate;
    }

    th,
    td {
      border-inline-end: 1px solid $slate;

      &:nth-child(1) {
        width: 39%;

        @include breakpoint($lg) {
          width: 33%;
        }
      }

      &:nth-child(2) {
        width: 26%;

        @include breakpoint($lg) {
          width: 33%;
        }
      }

      &:nth-child(3) {
        width: 35%;
        border-inline-end: 0;

        @include breakpoint($lg) {
          width: 33%;
        }
      }
    }
  }
}

.passport_feature_table__header_cell {
  padding-block-end: $g28;
}

.passport_feature_table__station_logo {
  width: fit-content;
  margin: auto;
  padding-block-end: $g12;
  cursor: default;

  img {
    width: auto;
    max-width: 100px;
    max-height: 30px;
  }
}

.passport_feature_table__station_passport_title {
  padding-block-end: $g12;
}

.passport_feature_table__header_description_passport,
.passport_feature_table__header_description_pbs {
  @include body-copy-3($pebble);

  margin: auto;
  padding-block-end: $g12;
}

.passport_feature_table__header_description_pbs {
  // magic number to lock the description width to display
  // consistently across breakpoints
  width: 152px;
}

.passport_feature_table__header_description_passport {
  // magic number for the same reason as above
  width: 228px;
}

.passport_feature_table__feature {
  @include body-copy-3;

  @include breakpoint($md) {
    @include body-copy-1;
  }

  padding: $g20 $g24 $g20 0;
}

.passport_feature_table__checkmark {
  svg {
    max-height: 20px;
    fill: white;
  }

  text-align: center;
}

.passport_feature_table_condensed {
  display: flex;
  flex-direction: column;
  align-items: center;

  .passport_feature_table_donate_button {
    margin-block-start: $g4;
  }

  @include breakpoint($sm) {
    display: none;
  }
}

.passport_feature_table_condensed__header {
  display: flex;
  padding-block: $g20;
  border-block-end: 1px solid $slate;
}

.passport_feature_table_condensed__header_item {
  display: flex;
  flex-direction: column;
  gap: $g12;
  width: 50%;
  padding-inline: $g8;
  text-align: center;

  @include body-copy-3($pebble);

  h3 {
    @include h6($fog);

    @include breakpoint($below-sm) {
      font-size: $f12;
    }
  }
}

.passport_feature_table_condensed__feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-block: $g16;
  border-block-end: 1px solid $slate;
  text-align: center;

  @include body-copy-3;

  &:last-of-type {
    border-block-end: 0;
  }
}

.passport_feature_table__two_checkmarks,
.passport_feature_table__one_checkmark {
  display: flex;
  width: 50vw;
  margin-block-start: $g16;

  svg {
    width: 20px;
  }
}

.passport_feature_table__two_checkmarks {
  justify-content: space-between;
}

.passport_feature_table__one_checkmark {
  justify-content: end;
}
