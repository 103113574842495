@use '../../includes' as *;

.passport_learn_more_hero {
  position: relative;
}

.passport_learn_more_hero_image {
  --blur-line: 180px;

  position: absolute;
  inset: 0 0 auto;
  z-index: 1;
  width: 100%;
  mask-image: linear-gradient(to bottom, black 0, transparent var(--blur-line));

  @include breakpoint($sm) {
    --blur-line: 70%;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

.passport_learn_more_info {
  display: grid;
  position: relative;
  z-index: 2;
  grid-template-areas:
    'donate'
    'community'
    'access';
  row-gap: $g20;
  padding: $g68 var(--spacing-inline) $g32 var(--spacing-inline);
  text-align: center;

  @include breakpoint($sm) {
    grid-template-areas:
      '. donate donate .'
      'community community access access';
    justify-content: center;
    column-gap: 5vw;
    row-gap: 3vw;
    padding-block-start: 18%;
  }
}

.passport_learn_more_hero_donate_blurb {
  display: flex;
  grid-area: donate;
  flex-direction: column;
  align-items: center;
  gap: $g8;

  @include breakpoint($sm) {
    gap: $g12;
  }

  .donate_text {
    @include h2;

    max-width: 350px;
    margin-block-end: $g8;

    @include breakpoint($sm) {
      width: clamp(300px, 50vw, 475px);
      max-width: none;
    }

    @include breakpoint($xl) {
      margin-block-end: $g12;
    }
  }

  .member_lookup {
    font-size: $f12;
    line-height: 2;

    a {
      margin-inline-start: $g4;
      transition: opacity var(--duration-half) ease-in;
      color: $passport-blue;
      font-weight: $bold;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}

.passport_learn_more_hero_community_blurb,
.passport_learn_more_hero_access_blurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g4;

  @include breakpoint($sm) {
    gap: $g12;
  }

  .heart_icon {
    width: 17px;
    height: 15px;

    @include breakpoint($sm) {
      width: 20px;
      height: 18px;
    }

    @include breakpoint($xl) {
      width: 23px;
      height: 20px;
    }
  }

  .play_icon {
    width: 15px;
    height: 15px;
    padding: 3px 3px 3px 4px;
    border: 1px solid $white;
    border-radius: 50%;
    fill: $white;

    @include breakpoint($sm) {
      width: 20px;
      height: 20px;
      margin-block-end: -2px;
      padding: 3.5px 3.5px 3.5px 5px;
      border-width: 2px;
    }

    @include breakpoint($xl) {
      width: 22px;
      height: 22px;
      margin-block-end: -2px;
      padding: 4.5px 4.5px 4.5px 6px;
    }
  }

  h2 {
    @include h3;
  }

  p {
    @include body-copy-3;

    text-wrap: pretty;

    @include breakpoint($sm) {
      @include body-copy-1;
    }
  }
}

.passport_learn_more_hero_community_blurb {
  grid-area: community;

  p {
    width: clamp(250px, 32vw, 380px);
  }
}

.passport_learn_more_hero_access_blurb {
  grid-area: access;

  p {
    width: clamp(250px, 32vw, 380px);
  }
}
